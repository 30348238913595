import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// SCSS
import { gutter } from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const ResearchPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="Our Science: Research and Development" />
      <RunReCaptcha action="science" />
      <div className={gutter}>
        <h1>Research &#38; Development</h1>

        <h2>Our Science</h2>

        <p>
          The Company’s interest in cancer pharmaceuticals began with the
          discovery by its scientists of a cell surface receptor for thyroid
          hormone and thyroid hormone analogues and the realization that this
          receptor was a regulator of cancer cell proliferation and of blood
          vessel proliferation (pathological angiogenesis). The integrin αvβ3
          protein bearing the hormone receptor is minimally expressed and not
          activated on non-dividing, non-malignant cells. The investigative team
          at the Company found that a thyroid hormone derivative which occurs
          naturally in human blood, tetraiodothyroacetic acid (tetrac), blocked
          the actions at the integrin of the latter’s principal ligand,
          L-thyroxine (T4). Tetrac-derived molecules with high affinity for the
          thyrointegrin αvβ3 receptors have been shown in preclinical studies to
          have effective anticancer activity whether T4 is present or not. The
          anticancer actions include disabling multiple cancer cell survival
          pathways, inhibiting the process of cancer cell division and
          systematically removing cancer-related blood vessels.
        </p>

        <p>
          To focus the actions of tetrac on anticancer activities, the Company
          chemically modified tetrac to generate compounds with slowed cell
          entry (and thus prolonged action at the tetrac receptor on the cell
          surface) and that were unable, once internalized by cells, to enter
          the cell nucleus. The chemical modifications of tetrac also increased
          the anticancer potency of the agents produced and desirably expanded
          the cancer cell gene repertoire that the modified compounds could
          affect. For example, transcription of the epidermal growth factor
          receptor (EGFR) gene is downregulated by modified tetrac, as are genes
          for PD-L1 and PD-1 immune checkpoint proteins and for signaling
          protein nuclear factor kappa B (NFκB); unmodified tetrac did not
          affect expression of these genes. It should be noted that each of the
          products (EGFR, NFκB, PD-L1) of these three genes is acted upon by
          individual anticancer drugs that are already available in the clinic.
          Tetrac-based agents also affect the transcription of multiple growth
          factor genes (see below); what is now available clinically are drugs
          that affect the actions of single growth factors. We would also point
          out that cancer cell gene expression is differentially regulated by
          our lead clinical candidates. That is, most of the disruptive actions
          of the anticancer compounds developed at NanoPharmaceuticals reflect
          downregulation of expression of genes that are a part of the natural
          defenses of cancer cells. In addition, tetrac-based compounds
          upregulate transcription of certain genes whose products serve to harm
          tumor cells. These include anti-angiogenic TSP1 and a nuclear
          inhibitor of beta-catenin, CBY1.
        </p>

        <p>
          The second aspect of the science of the Company’s compounds involves
          an attack on blood vessel formation via integrin αvβ3. This integrin
          via the cell surface thyroid receptor site is a highly specialized
          regulator on blood vessel cells of adjacent cell surface protein
          receptors for at least five vascular growth factors. These are VEGF,
          b-FGF, PDGF, EGF, and IGF-1 as well as other pro-angiogenesis factors.
          Modified high affinity tetrac molecules at the integrin interrupt via
          intermolecular crosstalk the binding of <em>all</em> of these vascular
          growth factors to their own receptors near the integrin.
        </p>

        <p>
          Finally, tetrac-based molecules block cancer cell repair of
          double-strand DNA breaks induced by radiotherapy in preclinical
          models. This enhances the effectiveness of radiotherapy where
          aggressive DNA repair is an inherent asset of cancer cells.{" "}
        </p>

        <p>
          <a href="https://www.nanopharmaceuticals.com/files/fcoi-policy.pdf">
            Financial Conflict of Interest Policy
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default ResearchPage;
